angular.module('MyHippoProducer.Controllers').directive('optionalScheduledPropertySection', function () {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: 'pages/policy-details/sections/optional-scheduled-property/optional-scheduled-property.html',
        controller: function ($scope, WorkflowAdapter, PolicyService) {
            $scope.specialLimits = WorkflowAdapter.getSection('optional_property_page', 'special_limits_section');
            $scope.isEditMode = PolicyService.isEditMode;
        }
    };
});
